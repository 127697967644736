<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view 
        :scroll="scroll"
        :height="height"
        :installed="installed"
        :modal="modal"
        :dev="dev"
      />
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'LoginView',
    
    props: {
      scroll: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },
  }
</script>
